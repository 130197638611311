<template>
  <div v-if="hasPerm('payments.view_sale')">
    <div class="sales-summary">
      <loading-gif :loading-name="loadingName"></loading-gif>
      <div v-if="!isLoading(loadingName)">
        <b-row>
          <b-col>
            <div class="sales-summary-item">
              <div class="summary-label">A facturer:</div>
              <div class="summary-value">{{ nonInvoicedTotal | currency }}</div>
            </div>
          </b-col>
          <b-col>
            <div class="sales-summary-item">
              <div class="summary-label">A payer:</div>
              <div class="summary-value">{{ nonPaidTotal | currency }}</div>
            </div>
          </b-col>
          <b-col>
            <div class="sales-summary-item">
              <div class="summary-label">Avoirs en cours:</div>
              <div class="summary-value">{{ creditsTotal | currency }}</div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<script>

import { mapMutations, mapActions } from 'vuex'
import store from '@/store'
import { BackendApi } from '@/utils/http'
import LoadingGif from '@/components/Controls/LoadingGif'
import { BackendMixin } from '@/mixins/backend'

export default {
  name: 'sales-summary',
  components: {
    LoadingGif,
  },
  mixins: [
    BackendMixin
  ],
  data() {
    return {
      loadingName: 'sales-summary',
      nonInvoicedTotal: 0,
      nonPaidTotal: 0,
      creditsTotal: 0,
    }
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async loadSummary() {
      this.startLoading(this.loadingName)
      try {
        const backendApi = new BackendApi('get', '/api/sales-summary/')
        const resp = await backendApi.callApi()
        this.nonInvoicedTotal = resp.data['non_invoiced_total']
        this.nonPaidTotal = resp.data['non_paid_total']
        this.creditsTotal = resp.data['credits_total']
      } catch (err) {
        this.addError(this.getErrorText(err))
      }
      this.endLoading(this.loadingName)
    },
  },
  created() {
    this.loadSummary()
  },
}
</script>
<style scoped lang="less">
.summary-label {
  font-size: 24px;
  display: inline-block;
}
.summary-value {
  display: inline-block;
  font-size: 24px;
  font-weight: bold;
  padding: 0 5px;
}
.sales-summary-item {
  padding: 10px;
  background: #444;
  color: #fff;
}
</style>
