<template>
  <div class="search" v-if="hasPerm('payments.view_invoice')">
    <b-form @submit="onSearch">
      <b-form-group
        id="search-group"
        label-for="invoices-search"
      >
        <b-form-row>
          <b-col cols="12">
            <b-input-group prepend="Recherche" class="mt-3 input-group-primary">
              <b-form-input
                type="search"
                placeholder="N° de facture"
                v-model="searchText"
                autocomplete="off"
              >
              </b-form-input>
              <b-input-group-append>
                <b-button type="submit" variant="primary">
                  <i class="fa fa-search"></i>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-col>
          <div v-if="found.length" class="found-invoices">
            <div v-for="invoice of found" :key="invoice.id">
              <a href @click.prevent="viewInvoice(invoice)">{{ invoice.getNumber() }}</a>
            </div>
          </div>
        </b-form-row>
      </b-form-group>
    </b-form>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import router from '@/router'
import { BackendMixin } from '@/mixins/backend'
import { BackendApi } from '@/utils/http'
import { makeInvoice } from '@/types/payments'

export default {
  name: 'SearchInvoice',
  mixins: [BackendMixin],
  data() {
    return {
      searchText: '',
      found: [],
    }
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    onSearch(evt) {
      evt.preventDefault()
      this.searchInvoice(this.searchText)
    },
    async viewInvoice(invoice) {
      await router.push({ name: 'invoice-detail', params: { invoiceId: invoice.id, }, })
    },
    async searchInvoice(text) {
      this.found = []
      if (text) {
        try {
          let url = '/api/invoices-search/?number='
          const backendApi = new BackendApi('get', url + text)
          const resp = await backendApi.callApi()
          let invoices = await resp.data.map(makeInvoice)
          if (invoices.length === 1) {
            await this.viewInvoice(invoices[0])
          } else if (invoices.length > 1) {
            this.found = invoices
          } else {
            await this.addError('La facture N° ' + text + ' ne peut pas être trouvée')
          }
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.found-invoices {
  font-size: 14px;
  padding: 5px;
}
</style>
